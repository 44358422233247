import { Component,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Step } from 'src/app/domain/common/stepper/step';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { ConnecteurService } from '../connecteur.service';
import { ImportAttachmentsComponent } from './import-attachments.component';
import { ImportSpecificationComponent } from './import-specification.component';
import { ImportFormatComponent } from './import-format.component';
import { ImportResumeComponent } from './import-resume.component';
import { ImportModuleListComponent } from './import-module-list.component';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	templateUrl: './import-selection.component.html'
})
export class ImportSelectionComponent implements OnInit {
	/** Type de module **/
	typeModule: TypeModule;

	/** Liste des droits **/
	listeTypesDroit: Array<TypeDroit>

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Module sélectionné **/
	selectedModule: any = null;

	/** Fournisseur sélectionné **/
	selectedFournisseur: any = null;

	/** Format sélectionné **/
	selectedFormat: any = null;

	/** Connecteur sélectionné **/
	selectedConnecteur: any = null;

	/** Template d'import sélectionné **/
	selectedTemplate: any = null;

	/** Vérification de la disponibilité du connecteur sélectionné **/
	selectedConnecteurAvailability: { isAvailable: boolean } = null;

	/** Indicateur de désactivation de l'import **/
	isActionDisabled = false;

	/** Indicateur de sélection globale des modules **/
	isGlobal: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImportSelectionComponent>,private connecteurService: ConnecteurService,private translateService: TranslateService,private toastrService: ToastrService,private rightService: RightService,private layoutService: LayoutService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification des droits et d'un import global
		this.isGlobal = !this.typeModule && (this.rightService.isRoot() || this.rightService.isRoot(true));

		//Définition de la liste des étapes
		this.listeSteps = [this.isGlobal && {
			type: 'MODULE',
			libelle: 'connecteur.import.selection.step.module',
			component: ImportModuleListComponent,
			retrieveComponentData: () => ({
				onModuleSelected: module => {
					//Mise à jour du module sélectionné
					this.selectedModule = module;

					//Retrait de la sélection des sous-éléments
					this.selectedFournisseur = null;
					this.selectedFormat = null;
					this.selectedConnecteur = null;
					this.selectedTemplate = null;

					//Vérification de la sélection d'un module
					if (module) {
						//Définition du type de module
						this.typeModule = module.typeModule;

						//Passage à l'étape suivante
						this.stepperComponent.selectStep(this.listeSteps[1]);
					}
				}
			}),
			canActivate: () => true,
			isDone: () => !!this.selectedModule
		},{
			type: 'FORMAT',
			libelle: 'connecteur.import.selection.step.format',
			component: ImportFormatComponent,
			retrieveComponentData: () => ({
				typeModule: this.typeModule,
				selectedModule: this.selectedModule,
				selectedFournisseur: this.selectedFournisseur,
				selectedFormat: this.selectedFormat,
				listeTypesDroit: this.listeTypesDroit,
				onItemSelected: (payload?: { fournisseur?: any,format?: any }) => {
					let isItemSelected: boolean = false;

					//Vérification du type d'élément
					if (payload?.fournisseur) {
						//Mise à jour du fournisseur sélectionné
						this.selectedFournisseur = payload.fournisseur;

						//Retrait du connecteur sélectionné
						this.selectedFormat = null;
						this.selectedConnecteur = null;
						this.selectedTemplate = null;

						//Elément sélectionné
						isItemSelected = true;
					} else if (payload?.format) {
						//Mise à jour du format sélectionné
						this.selectedFormat = payload.format;

						//Retrait du connecteur sélectionné
						this.selectedFournisseur = null;
						this.selectedConnecteur = null;
						this.selectedTemplate = null;

						//Elément sélectionné
						isItemSelected = true;
					} else {
						//Retrait des éléments sélectionnés
						this.selectedFournisseur = null;
						this.selectedFormat = null;
						this.selectedConnecteur = null;
						this.selectedTemplate = null;
					}

					//Passage à l'étape suivante
					this.stepperComponent.selectStep(this.listeSteps[!this.isGlobal ? (isItemSelected ? 1 : 0) : (isItemSelected ? 2 : 1)]);
				}
			}),
			canActivate: () => !!this.typeModule || !this.typeModule && !this.isGlobal,
			isDone: () => !!this.selectedFournisseur || !!this.selectedFormat
		},{
			type: 'SPECIFICATION',
			libelle: 'connecteur.import.selection.step.specification',
			component: ImportSpecificationComponent,
			retrieveComponentData: () => ({
				typeModule: this.typeModule,
				selectedModule: this.selectedModule,
				selectedFournisseur: this.selectedFournisseur,
				selectedConnecteur: this.selectedConnecteur,
				selectedFormat: this.selectedFormat,
				selectedTemplate: this.selectedTemplate,
				onItemSelected: (payload?: { connecteur?: any,template?: any }) => {
					//Retrait ou mise à jour du connecteur sélectionné
					this.selectedConnecteur = payload?.connecteur || null;

					//Retrait ou mise à jour du template sélectionné
					this.selectedTemplate = payload?.template || null;

					//Vérification de la sélection d'un connecteur
					if (payload?.connecteur) {
						//Vérification du mode d'exécution du connecteur
						if (payload?.connecteur.typeExecution != 'MIDDLEWARE') {
							//Vérification de la disponibilité du connecteur sélectionné
							this.connecteurService.checkConnecteurAvailability(this.selectedConnecteur.idConnecteur).subscribe({
								next: isAvailable => {
									//Mise à jour de la disponibilité du connecteur
									this.selectedConnecteurAvailability = { isAvailable };

									//Passage à l'étape suivante
									this.stepperComponent.selectStep(this.listeSteps[!this.isGlobal ? isAvailable ? 2 : 3 : isAvailable ? 3 : 4]);
								},
								error: () => {
									//Mise à jour de la disponibilité du connecteur
									this.selectedConnecteurAvailability = { isAvailable: false };

									//Passage à l'étape suivante
									this.stepperComponent.selectStep(this.listeSteps[this.isGlobal ? 4 : 3]);
								}
							});
						} else {
							//Mise à jour de la disponibilité du connecteur
							this.selectedConnecteurAvailability = { isAvailable: true };

							//Passage à l'étape suivante
							this.stepperComponent.selectStep(this.listeSteps[this.isGlobal ? 3 : 2]);
						}
					} else if (payload?.template) {
						//Mise à jour de la disponibilité du connecteur
						this.selectedConnecteurAvailability = { isAvailable: true };

						//Définition du connecteur sélectionné
						this.selectedConnecteur = {
							importTemplate: this.selectedTemplate
						};

						//Passage à l'étape suivante
						this.stepperComponent.selectStep(this.listeSteps[this.isGlobal ? 3 : 2]);
					} else
						//Rafraichissement de la liste
						this.stepperComponent.selectStep(this.listeSteps[this.isGlobal ? 2 : 1]);
				}
			}),
			canActivate: () => !!this.selectedFournisseur || !!this.selectedFormat,
			isDone: () => !!this.selectedFournisseur && !!this.selectedConnecteur || !!this.selectedFormat && !!this.selectedTemplate
		},{
			type: 'ATTACHMENTS',
			libelle: 'connecteur.import.selection.step.fichiers',
			component: ImportAttachmentsComponent,
			retrieveComponentData: () => ({
				typeModule: this.typeModule,
				selectedModule: this.selectedModule,
				selectedConnecteur: this.selectedConnecteur,
				selectedFormat: this.selectedFormat,
				selectedTemplate: this.selectedTemplate
			}),
			canActivate: () => (!!this.selectedFournisseur && !!this.selectedConnecteur || !!this.selectedFormat && !!this.selectedTemplate) && this.selectedConnecteurAvailability?.isAvailable,
			isDone: () => (!!this.selectedFournisseur && !!this.selectedConnecteur || !!this.selectedFormat && !!this.selectedTemplate) && this.selectedConnecteurAvailability?.isAvailable && this.selectedConnecteur?.listeLinks?.length
		},{
			type: 'RECAPITULATIF',
			libelle: 'connecteur.import.selection.step.recapitulatif',
			component: ImportResumeComponent,
			retrieveComponentData: () => ({
				selectedFournisseur: this.selectedFournisseur,
				selectedConnecteur: this.selectedConnecteur,
				typeModule: this.typeModule,
				selectedModule: this.selectedModule,
				selectedFormat: this.selectedFormat,
				selectedTemplate: this.selectedTemplate,
				selectedConnecteurAvailability: this.selectedConnecteurAvailability
			}),
			canActivate: () => (!!this.selectedFournisseur && !!this.selectedConnecteur || !!this.selectedFormat && !!this.selectedTemplate) && !!this.selectedConnecteurAvailability && (!this.selectedConnecteurAvailability.isAvailable || this.selectedConnecteur?.listeLinks?.length),
			isDone: () => false
		}].filter(s => !!s);
	}

	/**
	 * Exécution de l'interface
	 */
	doImport() {
		//Désactivation de l'import
		this.isActionDisabled = true;

		//Mise à jour de la liste des attachments
		this.selectedConnecteur.listeAttachments = this.selectedConnecteur.listeLinks.map(link => link.attachment);

		//Exécution de l'interface
		this.connecteurService.executeInterface(this.selectedConnecteur,!!this.selectedConnecteur.importTemplate).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('connecteur.import.selection.success'));

					//Fermeture de la fenêtre
					this.bsModalRef.hide();
				} else if (result.codeErreur) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('connecteur.import.selection.error'));

					//Ré-activation de l'import
					this.isActionDisabled = false;
				} else if (this.selectedConnecteur.importTemplate) {
					//Fermeture de la fenêtre
					this.bsModalRef.hide();
				}
			}
		})
	}
}