import { Component,Input,OnInit,Output } from '@angular/core';
import { first,map,of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { ImportTemplateService } from 'src/app/share/components/import-template/import-template.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { ImportFormatFournisseurListItemComponent } from './import-format-fournisseur-list-item.component';
import { ImportFormatOperationListItemComponent } from './import-format-operation-list-item.component';

@Component({
	templateUrl: './import-format.component.html'
})
export class ImportFormatComponent implements OnInit {
	/** Liste des fournisseurs **/
	listeConnecteurs: ListView<any,ImportFormatFournisseurListItemComponent>;

	/** Liste des formats d'import par template **/
	listeFormats: ListView<any,ImportFormatOperationListItemComponent>;

	/** Type de module **/
	@Input() typeModule: TypeModule;

	/** Module sélectionné **/
	@Input() selectedModule: any = null;

	/** Fournisseur sélectionné **/
	@Input() selectedFournisseur: any = null;

	/** Format sélectionné **/
	@Input() selectedFormat: any = null;

	/** Liste des droits **/
	@Input() listeTypesDroit: any = null;

	/** Format sélectionné **/
	@Output() onItemSelected: (payload?: { fournisseur?: any,format?: any }) => void;

	/** Types de choix disponibles **/
	typeChoixAvailable: 'FOURNISSEUR' | 'FORMAT' | 'BOTH';

	/** Type de choix réalisé **/
	typeChoix: 'FOURNISSEUR' | 'FORMAT';

	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService,private importTemplateService: ImportTemplateService,private connecteurService: ConnecteurService,private translateService: TranslateService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification des connecteurs disponibles
		this.connecteurService.isImportAvailable(this.typeModule).subscribe({
			next: result => {
				//Définition des types de choix disponibles
				this.typeChoixAvailable = result.isAvailableConnecteurs && result.isAvailableImport ? 'BOTH' : result.isAvailableConnecteurs ? 'FOURNISSEUR' : 'FORMAT';

				//Vérification du type de choix disponible
				if (['BOTH','FOURNISSEUR'].includes(this.typeChoixAvailable)) {
					//Initialisation du type de choix
					this.typeChoix = 'FOURNISSEUR';

					//Définition de la liste
					this.listeConnecteurs = new ListView<any,ImportFormatFournisseurListItemComponent>({
						uri: `${environment.baseUrl}/controller/Connecteur/filtreConnecteursGroupByFournisseur/${this.typeModule}`,
						component: ImportFormatFournisseurListItemComponent,
						isLocal: true,
						nbObjetsParPage: 12,
						isContentHidden: true,
						extraOptions: {
							onItemSelected: this.onItemSelected
						},
						listeFilters: [{
							clef: 'type.fournisseurInterface.fournisseur',
							type: TypeFilter.AUTOCOMPLETE,
							autocomplete: {
								type: 'fournisseur'
							},
							isDefault: true
						}],
						listeStaticFilters: [{
							clef: 'isActif',
							type: TypeFilter.BOOLEAN,
							valeur: true
						}]
					});
				} else
					//Initialisation du type de choix
					this.typeChoix = 'FORMAT';

				//Vérification des droits
				if (this.rightService.isRoot() || this.rightService.isRoot(true)) {
					//Récupération des formats du module (si sélectionné)
					(this.selectedModule ? of(this.selectedModule) : this.importTemplateService.retrieveListeModules().pipe(
						first(),
						map(listeModules => listeModules.find(module => module.typeModule == this.typeModule))
					)).subscribe({
						next: module => {
							//Définition du module sélectionné
							this.selectedModule = module;

							//Définition de la liste
							this.listeFormats = new ListView<any,ImportFormatOperationListItemComponent>({
								uri: () => null,
								component: ImportFormatOperationListItemComponent,
								isLocal: true,
								noSearch: true,
								isLoadingDisabled: true,
								extraOptions: {
									onItemSelected: this.onItemSelected
								}
							});

							//Définition du contenu statique de la liste
							this.listeFormats.data = {
								content: (module.listeFormats || []).filter(f => {
									let formatTypeDroit: TypeDroit;

									//Lecture du droit présent sur le format
									formatTypeDroit = f.mapRights?.right?.substring(0,f.mapRights?.right.lastIndexOf('_')) as TypeDroit;

									//Vérification des droits
									return this.listeTypesDroit.length ? this.listeTypesDroit?.includes(formatTypeDroit) : true;
								})
								.map(f => ({
									...f,
									libelle: this.translateService.instant(`connecteur.import.entite.${f?.className?.split('.').pop()}`),
									mode: this.translateService.instant(`connecteur.import.typeMode.${f?.typeMode}`)
								}))
								.sort((a,b) => {
									let comparisonByLibelle: number;

									//Comparaison des libellés
									comparisonByLibelle = a.libelle.localeCompare(b.libelle);

									//Vérification de la nécessité de comparer par mode (si les libellés sont identiques)
									if (comparisonByLibelle == 0)
										//Retour de la comparaison par mode
										return a.mode.localeCompare(b.mode);
									else
										//Retour de la comparaison par libellé
										return comparisonByLibelle;
								})
							};
						}
					});
				}
			}
		});
	}
}