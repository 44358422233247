import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest,Observable,of,Subject } from 'rxjs';
import { delay,first,map,switchMap,tap } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ImportSelectionComponent } from './import/import-selection.component';
import { PleaseWaitService } from 'src/app/share/components/please-wait/please-wait.service';
import { PleaseWaitModalComponent } from 'src/app/share/components/please-wait/please-wait-modal.component';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from '../messaging/messaging.service';
import { ProgressService } from '../../layout/progress/progress.service';
import { ImportResumeExecutionComponent } from './import/import-resume-execution.component';
import { ImportService } from 'src/app/components/connecteur/import/import.service';

@Injectable()
export class ConnecteurService {
	/** Cache des présences d'imports par type de module **/
	private mapImportsForTypeModule: { [typeModule: string]: { isAvailable: boolean,isAvailableConnecteurs: boolean,isAvailableImport: boolean } } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private bsModalService: BsModalService,private pleaseWaitService: PleaseWaitService,private translateService: TranslateService,private rightService: RightService,private messagingService: MessagingService,private progressService: ProgressService,private importService: ImportService) { }

	/**
	 * Synchronisation des connecteurs
	 */
	public syncConnecteurs(): Observable<Result> {
		//Synchronisation des connecteurs
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/syncConnecteurs`,null);
	}

	/**
	 * Authentification sur le connecteur
	 */
	public login(connecteur: any): Observable<Result> {
		//Authentification sur le connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/login`,connecteur);
	}

	/**
	 * Exécution du connecteur
	 */
	public execute(connecteur: any): Observable<Result> {
		//Exécution du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/execute`,connecteur);
	}

	/**
	 * Vérification de la disponibilité d'un import
	 */
	public isImportAvailable(typeModule?: TypeModule): Observable<{ isAvailable: boolean,isAvailableConnecteurs: boolean,isAvailableImport: boolean }> {
		//Lecture du type de module (depuis la route si nécessaire)
		typeModule = typeModule || this.layoutService.getExtendedRouteData().typeModule;

		//Vérification de l'absence de cache
		if (this.mapImportsForTypeModule[typeModule] === undefined) {
			//Vérification de la disponibilité des imports (connecteurs ou templates d'import)
			return combineLatest([this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/countConnecteurs/${typeModule}`,{
				listeFilter: [{
					clef: 'type.typeConnecteur',
					valeur: 'INTERFACE',
					typeFilter: TypeFilter.STRING,
					typeComparaison: TypeComparaison.EQUAL
				}]
			}).pipe(first(),map(result => !!result?.data?.nbConnecteurs)),this.rightService.isRoot() || this.rightService.isRoot(true) ? this.http.post<Result>(`${environment.baseUrl}/controller/ImportTemplate/isModuleAvailable/${typeModule}`,null).pipe(first(),map(result => !!result?.data?.isAvailable)) : of(false)]).pipe(
				first(),
				tap(([isAvailableConnecteurs,isAvailableImport]) => this.mapImportsForTypeModule[typeModule] = { isAvailable: isAvailableConnecteurs || isAvailableImport,isAvailableConnecteurs,isAvailableImport }),
				map(([isAvailableConnecteurs,isAvailableImport]) => ({ isAvailable: isAvailableConnecteurs || isAvailableImport,isAvailableConnecteurs,isAvailableImport }))
			);
		} else
			//Retour du cache
			return of(this.mapImportsForTypeModule[typeModule]);
	}

	/**
	 * Affichage de la popup d'import
	 */
	public showImport(typeModule?: TypeModule,listeTypesDroit?: Array<TypeDroit>) {
		//Lecture du type de module (depuis la route si nécessaire)
		typeModule = typeModule || typeModule === undefined && this.layoutService.getExtendedRouteData().typeModule || null;

		//Lecture de la liste des types de droit (depuis la route si nécessaire)
		listeTypesDroit = listeTypesDroit !== null && listeTypesDroit !== undefined ? listeTypesDroit : listeTypesDroit === undefined && Array.isArray(this.layoutService.getExtendedRouteData()?.typeDroit) ? this.layoutService.getExtendedRouteData()?.typeDroit as Array<TypeDroit> : [this.layoutService.getExtendedRouteData()?.typeDroit] as Array<TypeDroit>;

		//Affichage de la popup
		this.bsModalService.show(ImportSelectionComponent,{
			initialState: {
				typeModule,
				listeTypesDroit
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Vérification de la disponibilité d'un connecteur
	 */
	public checkConnecteurAvailability(idConnecteur: number): Observable<boolean> {
		let bsModalRef: BsModalRef<PleaseWaitModalComponent>;

		//Affichage du message d'attente
		bsModalRef = this.pleaseWaitService.show({
			message: this.translateService.instant('connecteur.import.selection.verification')
		});

		//Vérification de la disponibilité du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/checkConnecteurAvailability/${idConnecteur}`,null)
			.pipe(
				delay(200),
				map(result => result?.data?.connecteur?.actif),
				tap(() => bsModalRef.hide())
			);
	}

	/**
	 * Exécution de l'interface
	 */
	public executeInterface(connecteur: any,isWithWebSocket: boolean = false): Observable<Result | any> {
		let subject: Subject<{ result: Result }> = new Subject();
		let messaging$: MessagingObservables;
		let refProgress: string = null;
		let connecteurExecution: any;

		//Vérification de la présence d'une websocket
		if (isWithWebSocket) {
			//Initialisation de la progression
			refProgress = this.progressService.init({
				icone: 'upload',
				libelle: this.translateService.instant('connecteur.import.progression.title'),
				nbTotal: 0
			});

			//Démarrage de l'action par websocket
			messaging$ = this.messagingService.init({
				method: 'POST',
				entryPoint: `/controller/Connecteur/executeInterface/${connecteur.idConnecteur ? connecteur.idConnecteur : '0'}`,
				params: connecteur
			})
			.onResult({
				next: result => {
					//Transmission de la progression
					subject.next({ result });
				}
			})
			.onMessage({
				next: message => {
					//Vérification du message
					if (message.data?.message) {
						//Ajout du message à la liste
						this.progressService.updateProgress(refProgress,{
							message: message.data.message
						});
					}

					//Notification de la progression
					subject.next(message);

					//Vérification de la présence d'une exécution de connecteur
					if (message.data?.connecteurExecution)
						//Mise à jour de l'exécution
						connecteurExecution = message.data.connecteurExecution;
				}
			}).onFinish({
				next: (message: any) => {
					//Vérification du message
					if (message.data?.message) {
						//Ajout du message à la liste
						this.progressService.updateProgress(refProgress,{
							message: message.data.message
						});
					}

					//Notification de la progression
					subject.next(message);

					//Affichage de la popup de résumé de l'exécution avec un délai de 2 secondes
					of(true).pipe(
						delay(2000),
						tap(() => refProgress && this.progressService.refreshProgress(refProgress,1,0,1)),
						switchMap(() => this.showResumeExecution(connecteurExecution))
					).subscribe();

					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			}).onError({
				next: (message) => {
					//Vérification du message
					if (message.data?.message) {
						//Ajout du message à la liste
						this.progressService.updateProgress(refProgress,{
							message: message.data.message
						});
					}

					//Notification de la progression
					subject.next(message);

					//Génération d'une erreur
					subject.error(null);

					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});

			//Retour du sujet
			return subject.asObservable();
		} else
			//Exécution de l'interface
			return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/executeInterface${connecteur.idConnecteur ? '/' + connecteur.idConnecteur : ''}`,connecteur);
	}

	/**
	 * Suppression du cache
	 */
	public evictCache() {
		//Suppression du cache
		this.mapImportsForTypeModule = {};
	}

	/**
	 * Ouverture de la popup de résumé d'une exécution
	 */
	public showResumeExecution(connecteurExecution: any): Observable<any> {
		//Affichage de la popup
		return (this.bsModalService.show(ImportResumeExecutionComponent,{
			initialState: {
				connecteurExecution
			},
			class: 'modal-lg'
		})).onHidden.pipe(
			first()
		)
	}
}