import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Exchange, TypeScenario } from 'src/app/domain/chatbot/chatbot';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Injectable()
export class ChatbotService {
	/** Liste des scénarios **/
	private listeScenarios: Array<{ code: string,icon: string,disabled?: boolean }> = [];

	/** État du Chatbot **/
	private _isDisplayed: boolean = false;


	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private rightService: RightService) {
		//Définition de la liste des scénarios
		this.listeScenarios = [{
			code: TypeScenario.SEARCH_FAQ,
			icon: 'info'
		},{
			code: TypeScenario.CREATE_VEHICULE,
			icon: 'directions_car_filled',
			disabled: this.rightService.isRoot()
		},{
			code: TypeScenario.CREATE_SINISTRE,
			icon: 'car_crash',
			disabled: this.rightService.isRoot()
		},{
			code: TypeScenario.EXECUTE_CHART,
			icon: 'bar_chart'
		},{
			code: TypeScenario.EXECUTE_EXTRACTION,
			icon: 'download'
		},{
			code: TypeScenario.IMPORT_DATA,
			icon: 'upload'
		}];
	}

	/**
	 * Récupération de la liste des scénarios traduits
	 */
	public getListeScenarios(): Observable<Array<{ code: string,disabled?: boolean,icon: string,libelle: string,text: string }>> {
		//Attente de l'initialisation du service de traduction
		return this.translateService.get('chatbot.title').pipe(map(() => {
			//Retour de la liste des scénarios traduits
			return this.listeScenarios.map(scenario => ({
				...scenario,
				libelle: this.translateService.instant(`chatbot.scenario.${scenario.code}.libelle`),
				text: this.translateService.instant(`chatbot.scenario.${scenario.code}.confirmation`),
				textRoot: this.translateService.instant(`chatbot.scenario.${scenario.code}.confirmationRoot`)
			}));
		}));
	}

	/**
	 * Récupération de la liste des scénarios disponibles
	 */
	public findAllScenariosAvailable(): Observable<Result> {
		//Récupération de la liste des scénarios disponibles
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chatbot/findAllScenariosAvailable`,null);
	}

	/**
	 * Évaluation du job
	 */
	public evaluateJob(exchange: Exchange,evaluation: { score: number,comment: string }): Observable<Result> {
		//Évaluation du job
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chatbot/evaluateJob/${exchange.correlationId}` + (exchange.idChatbotExchange ? `/${exchange.idChatbotExchange}` : ''),evaluation);
	}

	/**
	 * Récupération de l'état d'affichage du chatbot
	 */
	public isDisplayed(): boolean {
		//Récupération de l'état du chatbot
		return this._isDisplayed;
	}

	/**
	 * Affichage du chatbot
	 */
	public toggleDisplay() {
		//Mise à jour de l'état du chatbot
		this._isDisplayed = !this._isDisplayed;
	}
}